<script lang="ts">
  import classnames from 'classnames';
  import { createEventDispatcher } from 'svelte';
  import { omit } from '$lib/utils/object';

  export let type: 'text' | 'password' | 'email' | 'date' | 'number' = 'text' ;
  export let id = Math.random().toString(36).substring(7);
  export let name: string;
  export let value: string = '';
  export let label: string;
  export let error: string | null | undefined = undefined;
  export let hint: string | null | undefined = undefined;
  export let valueFormatter: (val: string) => string = (val) => val;

  const attributes = omit($$restProps, 'class');

  const dispatcher = createEventDispatcher();

  let focused = false;

  const onBlur = () => {
    if (focused) {
      dispatcher('touch', true);
    }
  };

  const onInput = (e: Event) => {
    const input = e.target as HTMLInputElement;
    value = valueFormatter(input.value);
  };
</script>

<div class={classnames('text-field-floating', $$props.class)}>
  <input
    {id}
    {type}
    class="text-field"
    {name}
    {value}
    placeholder={label}
    on:blur={onBlur}
    on:focus={() => (focused = true)}
    on:input={onInput}
    on:change
    {...attributes}
  />
  <label class="label" for={id}>{label}</label>

  {#if hint}
    <span class="hint">{hint}</span>
  {/if}
  {#if error}
    <span class="error">{error}</span>
  {/if}
</div>

<style type="text/scss" lang="scss" scoped>
  .text-field-floating {
    @apply relative;
    @apply mb-3;
  }

  .text-field {
    @apply w-full;
    @apply outline-none shadow-none focus:outline-none focus:shadow-none focus:ring-0;
    @apply border-0 border-b border-gray-600 focus:border-brand;
    @apply h-11;
    @apply px-4 py-2;

    &::placeholder {
      @apply text-transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
      @apply pt-6 pb-2.5;

      + .label {
        @apply scale-80 -translate-y-3 translate-x-px;
      }
    }
    &:-webkit-autofill {
      @apply pt-6 pb-2.5;
    }
  }

  .label {
    @apply absolute top-0 inset-x-0;
    @apply h-full;
    @apply overflow-hidden;
    @apply truncate;
    @apply px-4 py-2;
    @apply border border-transparent;
    @apply opacity-50;
    @apply transform origin-left;
    @apply transition-transform duration-300;
    @apply pointer-events-none;
  }

  .hint {
    @apply text-xs;
  }
  .error {
    @apply text-xs;
    @apply text-error;
  }
</style>
